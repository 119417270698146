<template>
  <!-- 回收站彻底删除,单拿出来，要不样式有问题 -->
  <div class="right_box1" style="margin-bottom: 10px;" v-if="tabName =='fifth'">
    <colSetting name="回收站" btnName="显示列" @arrangeInfo="getInfo"  @load="loadingV"/> 
    <el-button size="small" type="danger" @click="delAll" v-if="this.isAdmin == 1">
      <el-icon><Delete /></el-icon> <span  >批量彻底删除</span>
    </el-button>
  </div>
  <div class="right_box">
    <colSetting name="记账客户" btnName="显示列" @arrangeInfo="getInfo" v-if="tabName == 'seventh' || tabName == 'sixth'" @load="loadingV"/> 
    <colSetting name="停报客户" btnName="显示列" @arrangeInfo="getInfo" v-if="tabName =='ninth'" @load="loadingV"/>
    <el-button size="small" type="primary" @click="batchEdit"  style="margin-left: 12px;"  v-if="tabName == 'seventh'|| tabName == 'sixth'" plain :disabled="!$buttonStatus('jzkh_plxg')">
      <el-icon><Edit /></el-icon><span  > 批量修改</span>
    </el-button>
    <el-button size="small" type="success" plain @click="companyfpAll" :disabled="!$buttonStatus('jzkh_fp')" v-if="this.tabName == 'sixth' || this.tabName == 'seventh'">
      <el-icon><User /></el-icon><span  > 分配</span>
    </el-button>
    <el-button size="small" type="danger" plain @click="stopAllCompany" :disabled="!$buttonStatus('jzkh_tb')" v-if="this.tabName == 'sixth' || this.tabName == 'seventh'">
      <el-icon><Warning /></el-icon> <span  > 停报</span>
    </el-button>
    <el-button size="small" type="primary" @click="customerExport"  :disabled="!$buttonStatus('jzkh_dc')"  v-if="tabName == 'seventh'|| tabName == 'sixth' || tabName =='ninth'" plain>
      <el-icon><FolderOpened /></el-icon><span  > 导出</span>
    </el-button>
    <el-dropdown size="small" :hide-on-click="false" v-if="this.tabName == 'sixth' || this.tabName == 'seventh'" style="margin-left: 12px;">
      <el-button size="small" type="primary">
        更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu style="text-align:center">
          <el-dropdown-item command="a" v-if="$buttonStatus('jzkh_plsz')">
            <span @click="setAll">批量设置</span>
          </el-dropdown-item>
          <el-dropdown-item command="h" v-if="$buttonStatus('jzkh_yjpx')">
            <span @click="oneKeySort">一键排序</span>
          </el-dropdown-item>
          <el-dropdown-item command="b" v-if="$buttonStatus('jzkh_sbzq')">
            <span @click="updateCycle">修改年报申报周期</span>
          </el-dropdown-item>
          <el-dropdown-item command="c" v-if="$buttonStatus('sjxx_xzsz')">
            <span @click="addTax">新增税种</span>
          </el-dropdown-item>
          <el-dropdown-item command="c" v-if="$buttonStatus('jzkh_szqyzt')">
            <span @click="updateState">修改税种启用状态</span>
          </el-dropdown-item>
          <el-dropdown-item command="d" v-if="$buttonStatus('jzkh_qych')">
            <span @click="updateStock">设置是否启用存货</span>
          </el-dropdown-item>
          <el-dropdown-item command="d">
            <span @click="combine">公司合并</span>
          </el-dropdown-item>
          <el-dropdown-item command="e">
            <span @click="updateProducer">修改制作人审核人</span>
          </el-dropdown-item>
          <el-dropdown-item command="f">
            <span @click="setLabel">设置标签</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button size="small" type="danger" @click="batchNinthDelete" v-if="this.tabName == 'ninth'" :disabled="!$buttonStatus('tbkh_plsc')">批量删除</el-button>
  </div>

  <div :style="{ height: `${contentStyleObj + 75}px` }">
    <n-data-table
      ref="tableDate"
      :columns="getTableColumns"
      :data="tableData"
      :max-height="`${contentStyleObj}px`"
      :row-key="row => row.id"
      :indent="14"
      :loading="loading"
      :scroll-x="nDataTablescrollX"
      :on-update:checked-row-keys="selectChangeEvent"
      :row-props="() => ({style: { height: '40px' }})"
      :single-line="false"
      :checked-row-keys="ids"
      row-class-name="bookkeeping-content-table-row"
      virtual-scroll
      bordered
      striped
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
    </n-data-table>
  </div>
  
  <!-- 分配 -->
  <companyFp ref="companyFp" code="bookkeeping" @success="getList"></companyFp>
  <div>
    <div class="bottom_button">
      <!-- 记账客户 -->
      <div v-if="this.tabName == 'sixth' || this.tabName == 'seventh'">
        <!-- <el-button size="small" type="primary" plain v-if="this.tabName == 'seventh' || this.tabName == 'sixth' " @click="settingMobile" :disabled="!$buttonStatus('jzkh_plszsjsjh') || !$buttonStatus('sqdl_sjhxs')">
          <el-icon><Iphone /></el-icon><span  > 设置办税手机号</span>
        </el-button> -->
        <el-button size="small" type="primary" plain v-if="this.tabName == 'seventh'" @click="nilReturn" :disabled="!$buttonStatus('jzkh_swlsb')">
          <el-icon><Switch /></el-icon><span  > 设为零申报</span>
        </el-button>
        <el-button size="small" type="primary" plain v-if="this.tabName == 'sixth'" @click="removeNilReturn" :disabled="!$buttonStatus('jzkh_yclsb')">
          <el-icon><Switch /></el-icon><span  > 移除零申报</span>
        </el-button>
      </div>
      <div v-if="this.tabName == 'ninth'">
        <el-button size="small" type="success" plain @click="recoveryAllCom" :disabled="!$buttonStatus('tbkh_hydjzkh')">
          <el-icon><RefreshLeft /></el-icon><span  > 还原到记账客户</span>
        </el-button>
        <!-- <el-button size="small" type="primary" plain @click="recoveryAllAddCom" :disabled="!$buttonStatus('tbkh_hydxzkh')">还原到新增账套</el-button> -->
      </div>
      <div v-if="this.tabName == 'fifth'">
        <el-button size="small" type="primary" plain @click="recycleAllRecover" :disabled="!$buttonStatus('tbkh_hydtbkh')">
          <el-icon><Refresh /></el-icon><span  > 还原到停报客户</span>
        </el-button>
      </div>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="contentTable"/>
    </div>
  </div>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="提示">
    <el-table :data="failList" border>
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="公司名称" min-width="180" >
        <template #default="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="失败原因" min-width="180" >
        <template #default="scope">
          {{scope.row.remark}}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <!-- 批量存货 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="stockVisible" title="开启存货设置" width="400px">
    <el-form :model="stockForm">
      <el-form-item label="是否开启：" :label-width="formLabelWidth">
        <el-radio-group v-model="stockForm.inventoryStatus">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="stockVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="stockSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <!-- 制作人 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="producerVisible" title="制作人审核人" width="550px">
    <div class="right_title none-border">
      <p>*温馨提示：为空默认登录人！</p>

      <div style="margin-bottom:20px;margin-left: 30px;">
        制作人：<el-input
          style="width:200px;"
          v-model="mergeSetInfo.createUser"
          placeholder="制作人"
          size="small"
        ></el-input>
        隐藏：
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="mergeSetInfo.createUserStatus"
        ></el-switch>
        （仅在打印时）
      </div>
      <div style="margin-left: 30px;">
        审核人：<el-input
          style="width:200px;"
          v-model="mergeSetInfo.examUser"
          placeholder="审核人"
          size="small"
        ></el-input>
        隐藏：
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="mergeSetInfo.examUserStatus"
        ></el-switch>
        （仅在打印时）
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="producerVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="producerSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>

  <deviceEduOrg ref="deviceEduOrg"></deviceEduOrg>
  <allotEdit ref="allotEdit" @success="getList" :listQuery1="listQuery"/>
  <archivesDialog ref="archivesDialog" type="3"/>
  <stopCompany ref="stopCompany" @success="getList"/>
  <delCom ref="delCom" @success="getList"/>
   <!-- 税局小助手未安装提示 -->
   <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
   <companySet ref="companySet" />
   <taxCategoryCycle ref="taxCategoryCycle" />
   <taxCategoryState ref="taxCategoryState" />
   <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
   <companyCombine ref="companyCombine" @success="getList"/>
   <batchAddCategory ref="batchAddCategory" />
   <companySort ref="companySort" @success="getList" />
</template>

<script lang="jsx">
import { eaCompanyList, changeStatus ,saveEaCompany,companyUserList,companyEaSetLable} from "@/api/company"
import { batchMergeSet } from "@/api/voucher"
import { companyMove,getComSecret } from "@/api/move"
import { inventoryStatusSetting } from '@/api/org' 
import { sendHelp,helpOver } from "@/api/help.js"
import companyFp  from "./companyFp";
import { assignCustomers } from "@/api/printSet"
import axios from 'axios'
import { loginTaxInfo } from "@/api/company"
import DeviceEduOrg from "./deviceEduOrg.vue"
import allotEdit from './allotEdit.vue'
import archivesDialog from "../../crm/custom/components/archivesDialog.vue"
import stopCompany from './stopCompany.vue'
import delCom from "./delCom.vue"
import companySet from './companySet.vue'
import taxCategoryCycle from './taxCategoryCycle.vue'
import taxCategoryState from "./taxCategoryState.vue"
import jotDown from '@/components/jotDown'
import companyCombine from "./companyCombine.vue";
import batchAddCategory from "./batchAddCategory.vue";
import DynamicPopover from '@/components/DynamicPopover/DynamicPopover'
import TableSortHeader from '@/components/table-cell-render/table-sort-header/TableSortHeader'
import TableSortCell from '@/components/table-cell-render/table-sort-cell/TableSortCell'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import KjTable from '@/components/table-cell-render/KjTable'
import companySort from './companySort.vue'
import taxBureau from "@/components/loginTaxAlert/taxBureau.vue"
export default {
  props: {
    tabName: String,
    listQuery: {}
  },
  data() {
    return {
      pageStatus: true,
      userTotal:0,
      selects:[],
      loading:false,  //表格加载
      visible:false,
      content:'',
      isAdmin:this.$store.getters['user/user'].isAdmin,
      noteContent:'',
      dialogConfirm:false,
      scroll:'',
      dialogTableVisible:false,
      failList:[],
      arrangeInfo:["编号","公司名称","当前账期","会计制度","税局","负责人","随手记","操作"],
      stockVisible:false,
      producerVisible:false,
      stockForm:{
        ids:[],
        inventoryStatus:1
      },
      ids: [],
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      gridData:[],//负责人数据
      nDataTablescrollX: 0,
      orgId: this.$store.getters["user/user"].orgId,
      mergeSetInfo:{  
        createUser:'',
        createUserStatus:0,
        examUser:'',
        examUserStatus:0,
        comIds:[]
      }
    }
  },
  components: {
    companyFp,
    DeviceEduOrg,
    allotEdit,
    archivesDialog,
    stopCompany,
    delCom,
    companySet,
    taxCategoryCycle,
    taxCategoryState,
    jotDown,
    companyCombine,
    batchAddCategory,
    companySort,
},
  computed: {
    getTableColumns() {
      const isSevenSix = ['seventh', 'sixth'].includes(this.tabName)
      const isNine = this.tabName == 'ninth'
      const isFifth = this.tabName == 'fifth'
      const columns = [
        {
          type: 'selection',
          width: 50,
          fixed: 'left',
        },
        ...this.arrangeInfo.includes('编号') ? [{
          key: 'sort',
          width: 70,
          align: 'center',
          fixed: 'left',
          title: () => {
            return <TableSortHeader onSortChange={data => this.sortChange(data)} />
          },
          render: (row) => {
            return <TableSortCell row={row}/>
          }
        }] : [],
        ...this.arrangeInfo.includes('标签') ? [{
          width: 70,
          align: 'center',
          fixed: 'left',
          title: '标签',
          key: 'labelLogoEa',
        }] : [],
        ...this.arrangeInfo.includes('公司名称') ? [{
          key: 'name',
          title: '公司名称',
          width: 300,
          fixed: 'left',
          render: (row) => {
            return <TagNameCopy row={row} show-auth-icon={isSevenSix} status={row.status}/>
          }
        }] : [],
        ...this.arrangeInfo.includes('启用账期') ? [{
          key: 'startAccountPeriod',
          title: '启用账期',
          dataKey: 'startAccountPeriod',
          width: 82,
          align: 'center',
        }] : [],
        ...this.arrangeInfo.includes('当前账期') ? [{
          key: 'CurPeriod',
          title: '当前账期',
          dataKey: 'CurPeriod',
          width: 82,
          align: 'center',
        }] : [],
        ...this.arrangeInfo.includes('最后结账月') ? [{
          key: 'lastJzPeriod',
          title: '最后结账月',
          dataKey: 'lastJzPeriod',
          width: 90,
          align: 'center',
        }] : [],
        ...this.arrangeInfo.includes('会计制度') ? [{
          key: 'accountSystem',
          title: '会计制度',
          dataKey: 'accountSystem',
          width: 228,
          ellipsis: true,
          render: (row) => {
            return <>
              <span>{ this.$accountSystemFilter(row.accountSystem) }</span>
            </>
          }
        }] : [],
        ...this.arrangeInfo.includes('税局') ? [{
          key: 'districtName',
          title: '税局',
          dataKey: 'districtName',
          width: 65,
        }] : [],
        ...this.tabName == 'fifth' && this.arrangeInfo.includes('法人代表') ? [{
          key: 'manager',
          title: '法定代表人',
          dataKey: 'manager',
          width: 100,
        }] : [],
        // ...this.arrangeInfo.includes('办税手机号') ? [{
        //   key: 'mobileRec',
        //   title: '办税手机号',
        //   dataKey: 'mobileRec',
        //   width: 120,
        //   render: (row) => {
        //     return <>
        //       {
        //         row.mobileRec ? <div class="item_icon" style="display: flex;align-items: center;">
        //           <masked-phone row={row} showAuthIcon={true} showPhone={this.$buttonStatus('sqdl_sjhxs')} />
        //         </div> : <div></div>
        //       }
        //     </>
        //   }
        // }] : [],
        // ...!this.$store.getters['user/user'].sysButton.includes('sqdl_sjhxs') && isSevenSix ? [{
        //   key: 'mobileRec',
        //   title: '授权',
        //   dataKey: 'mobileRec',
        //   align: 'center',
        //   width: 50,
        //   render: (row) => {
        //     return <>
        //       <div class="item_icon">
        //         {
        //           row.mobileRec ? <div style="display: inline-block;">
        //             {
        //               row.keepStatus != 1 ? <el-tooltip content="无税局登录权限，点击可以跳转到授权界面" placement="top" effect="dark">
        //                 <i
        //                   style="color:#afacac;cursor: pointer;font-weight:600"
        //                   class="iconfont icon-zidong"
        //                   onClick={() => this.$toLoginTax(row)}
        //                 ></i>
        //               </el-tooltip> : <el-tooltip content="已有税局登录权限" placement="top" effect="dark">
        //                 <i
        //                   style="color:var(--themeColor,#17a2b8);cursor: pointer;font-weight:600"
        //                   class="iconfont icon-zidong"
        //                 ></i>
        //               </el-tooltip>
        //             }
        //           </div> : <div />
        //         }
        //       </div>
        //     </>
        //   }
        // }] : [],
        ...isSevenSix && this.arrangeInfo.includes('负责人') ? [{
          key: 'kuaiji',
          title: '会计',
          width: 50,
          align: 'center',
          render: (row) => {
            return <div>
              <DynamicPopover v-slots={{
                reference: () => <div onClick={() => this.poper(row.id)} style="cursor:pointer;">
                  <i class="iconfont icon-huiji"></i>
                </div>,
                default: () => <KjTable list={this.gridData} />
              }} />
            </div>
          }
        }] : [],
        ...isNine && this.arrangeInfo.includes('停报时间') ? [{
          key: 'stopTime',
          title: '停报时间',
          dataKey: 'stopTime',
          minWidth: 180,
          render: (row) => {
            return <>
              <span>{ this.$parseTime(row.stopTime, "{y}-{m}-{d}")}</span>
            </>
          }
        }] : [],
        ...isNine && this.arrangeInfo.includes('停报原因') ? [{
          key: 'remark',
          title: '停报原因',
          dataKey: 'remark',
          minWidth: 180,
        }] : [],
        ...isFifth && this.arrangeInfo.includes('税种采集状态') ? [{
          key: 'setUpStatusCj',
          title: '税种采集状态',
          dataKey: 'setUpStatusCj',
          minWidth: 120,
          render: (row) => {
            return <>
              <el-tooltip placement="top" disabled={!row.FailReason} effect="dark" v-slots={{
                content: () => <div v-html={row.FailReason}></div>,
                default: () => <div class="item_icon bookkeeping-table-status">
                  <i class={this.setUpStatusIcon1(row.setUpStatusCj)}></i>
                  <p>{ this.setUpStatus1(row.setUpStatusCj) }</p>
                  {
                    row.FailReason ? <i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;"></i> : <div />
                  }
                </div>
              }} />
            </>
          }
        }] : [],
        ...isFifth && this.arrangeInfo.includes('上期税表采集') ? [{
          key: 'setUpStatusPp',
          title: '上期税表采集',
          dataKey: 'setUpStatusPp',
          minWidth: 150,
          render: (row) => {
            return <>
              <el-tooltip placement="top" disabled={!row.PpFailReason} effect="dark" v-slots={{
                content: () => <div v-html={this.Tobreak(row.PpFailReason)}></div>,
                default: () => <div class="item_icon bookkeeping-table-status">
                  <i class={this.setUpStatusIcon3(row.setUpStatusPp)}></i>
                  <p>{ this.setUpStatus3(row.setUpStatusPp) }</p>
                  {
                    row.PpFailReason && ([2, 4].includes(row.setUpStatusPp)) ? <i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;"></i> : <div />
                  }
                </div>
              }} />
            </>
          }
        }] : [],
        ...isFifth && this.arrangeInfo.includes('科目余额表采集状态') ? [{
          key: 'cjjzBussinessStatus',
          title: '科目余额表采集状态',
          dataKey: 'cjjzBussinessStatus',
          minWidth: 150,
          render: (row) => {
            const statusText = this.$setUpStatusJzCj(row.cjjzStatus, row.cjjzBussinessStatus)
            return <>
              <div class="div_p">
                <div class="item_icon bookkeeping-table-status">
                  <p>
                    <i class={this.$setUpStatusJzCjIcon(row.cjjzStatus, row.cjjzBussinessStatus)}></i>
                    {
                      row.cjjzBussinessLog ? <el-tooltip class="scope.row" effect="dark" placement="top-start" v-slots={{
                        content: () => <div v-html={row.cjjzBussinessLog ? row.cjjzBussinessLog : '' + (row.cjjzLog ? row.cjjzLog : '')} />,
                        default: () => <p>{statusText}</p>
                      }} /> : <span>
                        <p>{statusText}</p>
                      </span>
                    }
                  </p>
                </div>
              </div>
            </>
          }
        }] : [],
        ...isSevenSix && this.arrangeInfo.includes('备注') && this.$buttonStatus('jzkh_ssj')? [{
          key: 'jotDown',
          title: '随手记',
          dataKey: 'jotDown',
          width: 120,
          align: 'left',
          ellipsis: {},
          'ellipsis-component': 'ellipsis',
          render: (row) => {
            return <>
              <span class="xgmStyle" style="cursor:pointer;" onClick={() => this.smallNotes(row)}>记</span>
              <span>{row.jotDown}</span>
            </>
          }
        }] : [],
        ...isSevenSix && this.arrangeInfo.includes('档案') ? [{
          key: 'archivesList',
          title: '档案',
          dataKey: 'archivesList',
          width: 70,
          align: 'left',
          render: (row) => {
            return <>
              <el-button size="small" onClick={() => this.checkArchives(row)} type="primary" link icon="Reading" style="padding: 0;">档案</el-button>
            </>
          }
        }] : [],
        {
          key: 'opera',
          title: '操作',
          ...isSevenSix && {width: 270,},
          ...isFifth && {width: 280,},
          ...isNine && {width: 380,},
          align: 'center',
          render: (row) => {
            const hasTbkhJrzb = !this.$buttonStatus('jzkh_jrzb')
            if (isFifth) {
              return <>
                <el-button size="small" plain type="success" onClick={() => this.recycleRecover(row)} disabled={!this.$buttonStatus('tbkh_hydtbkh')} v-slots={{
                  default: () => <><el-icon><Refresh /></el-icon><span> 还原</span></>
                }} />
                {
                  this.isAdmin == 1 && <el-button size="small" plain type="danger" onClick={() => this.delCustomer(row)} v-slots={{
                    default: () => <><el-icon><Delete /></el-icon> <span>彻底删除</span></>  
                  }} />
                }
              </>
            }
            if (isSevenSix) {
              const hasJzkhQqxz = !this.$buttonStatus('jzkh_qqxz')
              const hasJzkhFp = !this.$buttonStatus('jzkh_fp')
              return <>
                {
                  row.crmComId == 0 ? <div>
                    <el-button onClick={() => this.$goEtax(row.id)} size="small" type="primary" plain disabled={hasTbkhJrzb} v-slots={{
                      default: () => <><el-icon><House /></el-icon><span> 进账簿</span></>
                    }} />
                    <taxBureau comId={row.id}  onSuccess={() => this.getList()} />
                    <el-dropdown size="small" v-slots={{
                      default: () => <el-button type="primary" size="small" plain style="margin-left:10px">
                        更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
                      </el-button>,
                      dropdown: () => <el-dropdown-menu style="text-align: left;">
                        <el-dropdown-item>
                          {
                            row.isHelp == 1 ? 
                              <el-button size="small" onClick={() => this.cancelEmpower(row)} type="primary" link disabled={hasJzkhQqxz} style="width:100%">取消协助</el-button> : 
                              <el-button size="small" type="primary" link onClick={() => this.empower(row)} disabled={hasJzkhQqxz} style="width:100%">请求协助</el-button>
                          }
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button size="small" type="primary" link onClick={() => this.createCompanyInfo(row)} disabled={!this.$buttonStatus('jzkh_bj')} style="width:100%">编辑</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button size="small" type="primary" link onClick={() => this.companyfp(row)} disabled={hasJzkhFp} style="width:100%">分配</el-button>
                        </el-dropdown-item>
                        {
                          this.$store.getters['user/user'].type == '教育' ? <el-dropdown-item>
                            <el-button size="small" type="primary" link onClick={() => this.deviceEduPack(row)} disabled={hasJzkhFp} style="width:100%">分配教学包</el-button>
                          </el-dropdown-item> : <div />
                        }
                        <el-dropdown-item>
                          <el-button size="small" type="primary" link onClick={() => this.stopCompany(row)} disabled={!this.$buttonStatus('jzkh_tb')} style="width:100%">停报</el-button>
                        </el-dropdown-item>
                        {
                          this.orgId == 3576 ? <el-dropdown-item>
                          <el-button size="small" type="primary" link onClick={() => this.getSecret(row)} style="width:100%">获取迁移密钥</el-button>
                        </el-dropdown-item> : <div />
                        }
                      </el-dropdown-menu>
                    }} />
                  </div> : <div>
                    {
                      row.errLog ? <el-tooltip class="item"  effect="dark" placement="top-start" v-slots={{
                        content: () => <div v-html={row.errLog}></div>,
                        default: () => <el-button size="small" type="primary" link onClick={() => this.gatherCJ(row)}>重新迁移</el-button>
                      }} /> : <el-button v-else size="small" type="primary" link onClick={() => this.gatherCJ(row)}>迁移</el-button>
                    }
                  </div>
                }
              </>
            }
            if (isNine) {
              return <>
                <el-button onClick={() => this.$goEtax(row.id)} size="small" type="primary" plain disabled={hasTbkhJrzb}>
                  <el-icon><House /></el-icon><span  > 进账簿</span>
                </el-button>
                <el-button size="small" onClick={() => this.recoveryCom(row)} plain type="success" disabled={!this.$buttonStatus('tbkh_hydjzkh')}>
                  <el-icon><RefreshLeft /></el-icon><span> 还原到记账客户</span>
                </el-button>
                <el-button size="small" plain type="danger" onClick={() => this.goRecycle(row)} disabled={!this.$buttonStatus('tbkh_sctbkh')}>
                  <el-icon><Delete /></el-icon><span> 删除</span>
                </el-button>
              </>
            }
            return <div />
          }
        },
      ]
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.nDataTablescrollX = columns.reduce((acc, item) => {
        acc += (item.width || item.minWidth)
        return acc
      }, 0)
      return columns
    },
  },
  created(){
    this.listQuery.limit = localStorage.getItem('contentTable') ? localStorage.getItem('contentTable')*1 : 20
    this.contentStyleObj= parseInt(this.$getHeight(286))
    this.initBus()
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  methods:{
    selectChangeEvent(keys, rows) {
      this.ids = keys
      this.selects = rows
    },
    // 列表点击
    poper(val){
      companyUserList({comId:val}).then(res=>{
        this.gridData = res.data.data.list
      })
    },
    loadingV(){
      this.loading = true
    },
    getInfo(e){
      this.arrangeInfo = e
      this.getList()
    },
    getList(){
      this.loading = true
      eaCompanyList(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.userTotal = res.data.data.total
        this.ids = []
        this.selects = []
      })
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.selects && this.selects.length != 0){
          return
        }
        if(this.tableData.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.tableData.forEach(res => {
          if ([3,99].includes(res.setUpStatusCj) || [3,99].includes(res.setUpStatusPp)) {
            status = true;
          }
        })
        if(this.tabName == 'fifth') {
          if(status == true) {
            eaCompanyList(this.listQuery).then(res=>{
              this.tableData = res.data.data.list ? res.data.data.list : []
              this.userTotal = res.data.data.total
            })
          }
        }
      });
    },
    // 请求协助
    empower(row){
      this.$confirm('确认使用远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sendHelp({comId:row.id}).then(res=>{
          if(res.data.msg == 'success'){
            this.$message({
              message: "请求成功",
              type: "success"
            });
            this.getList()
            this.$bus.emit('contentTable')
          }
        })
      })
    },
    // 取消协助
    cancelEmpower(row){
      this.$confirm('确认取消远程协助?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        helpOver({comIds:[row.id]}).then(res=>{
          if(res.data.msg == 'success'){
            this.$message({
              message: "已取消",
              type: "success"
            });
            this.getList()
          }
        })
      })
    },
    //分配
    companyfp(row){
      let ids = row.id
      ids = [ids]
      this.$refs.companyFp.init(ids,row)
    },
    companyfpAll(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.companyFp.init(ids)
    },
    //记账客户采集
    gatherCJ(row) {
      this.loading = true
      this.$confirm('要发起迁移任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        companyMove({id: row.id}).then(res => {
          if(res.data.msg == 'success') {
            this.$message({
              type: 'success',
              message: '任务已发起!'
            });
            this.getList()
          }
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      });
    },
   
    setUpStatus1(e) {
      let num = e;
      if (num == 1) {
        return "已采集";
      } else if (num == 6) {
        return "未采集";
      } else if (num == 2) {
        return "跳过采集";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      } else if (num == 5) {
        return "部分采集失败";
      } else if (num == 9) {
        return "采集中";
      }else if (num == 7) {
        return "任务已撤销";
      }else if (num == 99) {
        return "发起中";
      }
    },
    setUpStatus2(e) {
      let num = e;
      if (num == 1) {
        return "已初始化";
      } else if (num == 6) {
        return "未初始化";
      }else if (num == 2) {
        return "无需初始化"; //绿色
      }else if (num == 4) {
        return "请手动初始化"; //黄色
      }
    },
    setUpStatus3(e) {
      let num = e;
      if (num == 1) {
        return "采集成功";
      } else if (num == 6) {
        return "未采集";
      } else if (num == 2) {
        return "部分采集失败";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      }else if (num == 9) {
        return "采集中";
      }else if (num == 7) {
        return "任务已撤销";
      }else if (num == 10) {
        return "跳过采集";
      }else if (num == 99) {
        return "发起中";
      }
    },
    createCompanyInfo(e) {
      this.$store.dispatch('commons/setParam', {editCustomerComId: e})
      this.$router.push({
        path: "/company/editCustomer",
        name: "editCustomer"
      });
    },
    stopCompany(row){
      this.$refs.stopCompany.init([row])
    },
    stopAllCompany(){
      if(this.selects?.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.$refs.stopCompany.init(this.selects)
    },
    recoveryCom(row){
      row.status = 2
      changeStatus([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    recoveryAllCom(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 2
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    recoveryAddCom(row){
      row.status = 1
      changeStatus([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    recoveryAllAddCom(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 1
      })
      changeStatus(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
          this.$bus.emit('addCustomerUpdate')
        }
      })
    },
    nilReturn(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.accountingType = 1
      })
      saveEaCompany({list:this.selects}).then(res=>{
        if(res.data.msg == "success"){
          if(res.data.data.fail_total != 0){
            this.$qzfMessage("设置失败",2) 
            this.failList = res.data.data.fail_list
            this.dialogTableVisible = true
            this.getList()
          }else{
            this.$qzfMessage("设置成功") 
            this.getList()
          }
        }
      })
    },
    removeNilReturn(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.accountingType = 2
      })
      saveEaCompany({list:this.selects}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
        }
      })
    },
    goRecycle(row){
      row.status = 9
      this.$confirm('确定要删除该公司吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      });
    },
    batchNinthDelete(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 9
      })
      this.$confirm('确定要批量删除这些公司吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      });
    },
    delCustomer(row){
      this.$refs.delCom.init([row])
      // this.$refs.delCom.init([row],'content')
    },
    delAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.$refs.delCom.init(this.selects)
      // this.$refs.delCom.init(this.selects,'content')
    },
    settingMobile(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.$prompt('请输入办税人/法人手机号', '设置办税手机号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:'',
        beforeClose:(action,instance,done)=>{
          if(action == 'confirm'){
            let value = instance.inputValue;
            if(value && !/^1[3456789]\d{9}$/.test(value)){
              this.$qzfMessage('请输入正确手机号',1)
            }else{
              this.selects.map(v=>{
                v.mobileRec = value
              })
                // 用于一些手机号设置失败的公司
              saveEaCompany({list:this.selects}).then(res=>{
                done();
                if(res.data.data.fail_total != 0){
                  this.$qzfMessage("设置失败",2) 
                  this.failList = res.data.data.fail_list
                  this.dialogTableVisible = true
                  this.getList()
                }else{
                  this.dialogFormVisible = false;
                  this.$qzfMessage("设置成功") 
                  this.getList()
                }
              })
            }
          }else{
            done()
          }
        }
      })
    },
    setLabel(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.$prompt('请输入标签', '设置标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:'',
        beforeClose:(action,instance,done)=>{
          if(action == 'confirm'){
            let value = instance.inputValue;
            let param = {
              ids:this.selects.map(v=>v.id),
              labelLogoEa:value
            }
            companyEaSetLable(param).then(res=>{
              done();
              if(res.data.msg == "success"){
                this.$qzfMessage("设置成功")
                this.getList()
              }
            })
          }else{
            done()
          }
        }
      })
    },
    Tobreak(val){
      return val.replace(/[\r\n]/g, '<br/>')
    },
    recycleRecover(row){
      row.status = 3
      this.$confirm('确定还原该公司到停报客户吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("还原成功")
            this.getList()
          }
        })
      });
    },
    recycleAllRecover(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.selects.map(v=>{
        v.status = 3
      })
      this.$confirm('确定要还原该些公司到停报客户吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("还原成功")
            this.getList()
          }
        })
      });
    },
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.id,row.CurPeriod)
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.orderBy = "ea_company.sort_com desc,ea_company.id desc"
      }else{
        this.listQuery.orderBy = "ea_company.sort_com,ea_company.id desc"
      }
      this.getList()
    },
    customerExport(){
      let param = {
        query:this.listQuery,
        ids: this.selects.map(v=>{
          return v.id
        })
      }
      
      assignCustomers(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    InTax(row){
      // if(this.orgId == 3576){
      //   this.$qzfMessage('此功能暂时无法使用',1)
      //   return
      // }
      row.taxLoading = true
      let that = this
      loginTaxInfo({id:row.id}).then(res=>{
        let param = res.data.data.info
        if(param.isLock){
          this.$confirm('任务端机器人正在执行报税任务，如要强制进入税局，账号将会被顶掉，导致任务中断，您确认进入税局吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(() => {
            this.$qzfMessage('打开中，预计15秒...');
            axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
              row.taxLoading = false
              if(res.data.msg != "success"){
                this.getList()
                that.$alert(res.data.msg, '提示', {
                  confirmButtonText: '确定',
                });
              } 
            }).catch(function () {
              row.taxLoading = false
              setTimeout(() => {
                that.$refs.loginTaxAlert.dialogVisible = true
              }, 1000);
              return
            });
          }).catch(()=>{
            row.taxLoading = false
          });
         }else{
          this.$qzfMessage('打开中，预计15秒...');
          axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
            row.taxLoading = false
            if(res.data.msg != "success"){
              this.getList()
              that.$alert(res.data.msg, '提示', {
                confirmButtonText: '确定',
              });
            } 
          }).catch(function () {
            row.taxLoading = false
            setTimeout(() => {
              that.$refs.loginTaxAlert.dialogVisible = true
            }, 1000);
            return
          });
        }
      })
    },
    deviceEduPack(row){
      let ids = [row.id]
      this.$refs.deviceEduOrg.init(ids,row)
    },
     //更新随手记之后的回调
     updateNote(id,text){
      this.tableData.map(v=>{
        if(v.id == id){
          v.jotDown = text
        }
      })
    },
    //批量修改
    batchEdit(){
      // if(this.selects.length == 0){
      //   this.$qzfMessage("请选择公司", 1) 
      //   return
      // }
      this.$refs.allotEdit.init(this.selects)
    },
    //新增税种
    addTax(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      const ids = this.selects.map(v=>{
        return v.id
      })
      this.$refs.batchAddCategory.init(ids)
    },
    //查看档案
    checkArchives(row){
      this.$refs.archivesDialog.checkArchivesList(row)
    },
    //进入授权页面
    toLoginTax(row){
      let tel = ""
      let taxLoginMethod = ""
      if(row.mobileRec){
        tel = row.mobileRec
      }else{
        tel = row.name
      }
      if(row.loginMethod == '新版登录'){
        taxLoginMethod = 1
      }else if(row.loginMethod == "代理登录"){
        taxLoginMethod = 3
      }else{
        taxLoginMethod = 0
      }
      this.$store.dispatch("commons/setParam", { loginMobile: tel });
      this.$store.dispatch("commons/setParam", { taxLoginMethod: taxLoginMethod });
      this.$store.dispatch("tagsView/delCachedViewByName", "loginTaxBureau");
      this.$router.push({
        name:'loginTaxBureau',
      })
    },
    //批量设置
    setAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.$refs.companySet.init(this.selects)
    },
    //批量修改税种申报周期
    updateCycle(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.$refs.taxCategoryCycle.init(this.selects)
    },
    //修改税种启用状态
    updateState(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.$refs.taxCategoryState.init(this.selects)
    },
    updateStock(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.stockForm.ids = this.selects.map(v=>{
        return v.id
      })
      this.stockForm.inventoryStatus = 1
      this.stockVisible = true
    },
    stockSure(){
      inventoryStatusSetting(this.stockForm).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('设置成功');
          this.getList()
          this.stockVisible = false
        }
      })
    },
    combine(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.$refs.companyCombine.init(this.selects)
    },
    // 制作人审核人弹框
    updateProducer(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.producerVisible = true
    },
    producerSure(){
      this.mergeSetInfo.comIds = [];
        this.selects.map((v) => {
          this.mergeSetInfo.comIds.push(v.id);
        });
      batchMergeSet(this.mergeSetInfo).then(res=>{
        if(res.data.msg =='success'){
          this.$qzfMessage('设置成功');
          this.producerVisible = false
        }
      })
    },
    getSecret(row){
      getComSecret({id:row.id}).then(res=>{
        if(res.data.msg == 'success'){
          this.$copyComName(res.data.data.secret)
        }
      })
    },
    //一键排序
    oneKeySort(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.$refs.companySort.init(ids)
    },
    setUpStatusIcon1(type){
      if (type == 1) {
        return "iconfont icon-duihao";
      } else if (type == 6) {
        return "iconfont icon-gantanhao";
      } else if (type == 2) {
        return "iconfont icon-info";
      } else if (type == 3) {
        return "iconfont icon-info";
      } else if (type == 4) {
        return "iconfont icon-cuowu";
      } else if (type == 5) {
        return "iconfont icon-duihao duihao-yc";
      } else if (type == 9) {
        return "iconfont icon-info";
      }else if (type == 7) {
        return "iconfont icon-cuowu";
      }else if (type == 99) {
        return "iconfont icon-info";
      }
    },
    setUpStatusIcon3(type){
      if (type == 1) {
        return "iconfont icon-duihao";
      } else if (type == 6) {
        return "iconfont icon-gantanhao";
      } else if (type == 2) {
        return "iconfont icon-duihao duihao-yc";
      } else if (type == 3) {
        return "iconfont icon-info";
      } else if (type == 4) {
        return "iconfont icon-cuowu";
      }else if (type == 9) {
        return "iconfont icon-info";
      }else if (type == 7) {
        return "iconfont icon-cuowu";
      }else if (type == 10) {
        return "iconfont icon-info";
      }else if (type == 99) {
        return "iconfont icon-info";
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popverMain{
    cursor: pointer;
}
.el-dropdown-menu--small :deep(.el-dropdown-menu__item){
  padding: 2px 6px !important;
}
.circle{
  width:10px;
  height:10px;
  border-radius:50%;
  display: inline-block;
}
.small_cell {
  div{
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
}

.right_box2 {
  position: absolute;
  right: 19px;
  top: 11px;
  left: 402px;
}
.right_box3 {
  position: absolute;
  right: 19px;
  top: 15px;
  left: 400px;
}
.right_box {
  position: absolute;
  right: 19px;
  top: 13px;
}
.right_box1 {
  float: right;
  width: 21%;
  text-align: right;
  margin-bottom: 5px;
}
:deep(textarea){
  min-height: 145px !important;
}
.none-border p {
  line-height: 30px;
  text-align: left;
  text-indent: 1em;
  /// height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right_title {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
  h3 {
    font-size: 14px;
    padding-bottom: 10px;
  }
}
.right_title h5 {
  font-weight: normal;
}
.right_title p {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}
</style>
<style lang="scss">
  .classPrompt{
    .el-message-box__container{
      color:red
    }
}
.bookkeeping-table-status.item_icon{
  display: inline-block;
  // width: 59%;
  padding: 1px 3px;
  // padding-right: 10px;
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 13px;
    //line-height: 28px;
    color: #333;
    // margin-left: 4px;
  }
}
</style>