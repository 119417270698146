<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close width="20%" v-model="dialogTableVisible" title="分配">
    <div>
      <el-form :model="form">
        <el-form-item label="选择学校" :label-width="formLabelWidth">
          <el-select v-model="userIds1" placeholder="选择学校" multiple collapse-tags style="width: 80%;" size="small" filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id" size="small">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save()">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { findEduOrg ,authTechPkg } from '@/api/eduOrgCom'
export default {
  props: {
    code: '',
  },
  data(){
    return {
      formLabelWidth: "100px",
      dialogTableVisible: false,
      options: [],
      userIds1: [],
    }
  },
  methods: {
    init(comIds,row){
      if(comIds && comIds.length != 0){
        this.comIds = comIds
        findEduOrg({}).then(res => {
          this.options = res.data.data
          this.dialogTableVisible = true
        })
      }else{
        this.$qzfMessage("请选择公司", 1)
      }
    },
    save(){
      if(this.userIds1.length == 0){
        this.$qzfMessage("请选择责任人", 1)
        return
      }
      let parma = this.comIds.map(v=>{
        let arr = []
        this.userIds1.map(u=>{
          arr.push({
            org_id: u,
          })
        })
        return {
          comId: v * 1,
          orgItems: arr,
        }
      })
      authTechPkg(parma).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("客户分配成功")
          this.dialogTableVisible = false
          this.$emit("success")
        }
      })
      
    },
  }
}
</script>
<style scoped>

</style>
