<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="批量设置"
    v-model="dialogTableVisible"
    width="600px"
  >
    <div>
      修改项目：
      <el-select
        size="small"
        placeholder="请选择"
        clearable
        v-model="temp.type"
        style="width: 200px; margin: 0"
        filterable
      >
        <el-option
          v-for="item in list"
          :key="item.value"
          :label="item.name"
          :value="item.type"
        ></el-option>
      </el-select>
      <div v-if="temp.type" style="margin-top: 20px">
        <div v-for="(item, index) in list" :key="index">
          <div v-if="temp.type == item.type">
            <span style="display: inline-block; text-indent: 2em">选项：</span>
            <el-radio-group
              v-if="item.valueType == 'radio' && temp.type == item.type"
              size="small"
              v-model="temp.value"
            >
              <el-radio
                v-for="item in item.option"
                :label="item.value"
                :key="item.index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
            <el-input
              v-if="item.valueType == 'input' && temp.type == item.type"
              size="small"
              style="width: 200px"
              v-model="temp.value"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveSetting()"
          >保存</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { companyGets, companySets, batchSetSure } from "@/api/company";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      list: [],
      dialogTableVisible: false,
      contentStyleObj: {},
      sels: [],
      id: null,
      temp: {
        type: "",
        value: "",
      },
      defaultOption: [
        {
          type: "code_djqr",
          name: "单据确认",
          valueType: "radio",
          option: [
            { name: "默认", value: 0 },
            { name: "手工确认", value: 1 },
            { name: "无需确认", value: 2 },
          ],
        },
        {
          type: "code_skqr",
          name: "税款确认",
          valueType: "radio",
          option: [
            { name: "默认", value: 0 },
            { name: "手工确认", value: 1 },
            { name: "无需确认", value: 2 },
          ],
        },
      ],
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(300);
  },
  methods: {
    init(item) {
      let ids = [];
      item.map((v) => {
        ids.push(v.id);
      });
      this.sels = ids;
      this.temp = {
        type: "",
        value: "",
      };
      this.dialogTableVisible = true;
      companyGets({}).then((res) => {
        if (res.data.msg == "success") {
          this.list = res.data.data ? res.data.data : [];
          this.list = this.list.concat(this.defaultOption);
        }
      });
    },
    saveSetting() {
      if (!this.temp.type || !this.temp.value) {
        this.$qzfMessage("请补全信息", 1);
        return;
      }
      if (this.temp.type == "code_djqr") {
        let param = {
          comIds: this.sels,
          setType: "dj",
          djStatus: this.temp.value,
        };
        this.setStatus(param);
      } else if (this.temp.type == "code_skqr") {
        let param = {
          comIds: this.sels,
          setType: "sk",
          skStatus: this.temp.value,
        };
        this.setStatus(param);
      } else {
        let param = {
          comIds: this.sels,
          set: this.temp,
        };
        companySets(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("设置成功");
            this.dialogTableVisible = false;
          }
        });
      }
    },
    setStatus(param) {
      batchSetSure(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
          this.dialogTableVisible = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
