<template>
  <div class="sort-box" @click="sortChange">
    <span>编号</span>
    <span>
      <i class="sort-caret ascending" />
      <i class="sort-caret descending" />
    </span>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'

const emit = defineEmits(['sortChange'])
const order = ref('ascending')

function sortChange() {
  order.value = order.value === 'ascending'? 'descending' : 'ascending'
  emit('sortChange', {
    order: order.value
  })
}
</script>

<style scoped lang="scss">
.sort-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & > span {
    &:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3px;
    }
  }
  .sort-caret {
    width: 0;
    height: 0;
    border: solid 5px transparent;
  }
  .sort-caret.descending {
    border-top-color: #fff;
  }
  .ascending {
    border-bottom-color: #fff;
  }
}
</style>