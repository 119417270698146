<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="合并公司"
    v-model="dialogVisible"
    width="500px"
  >
    <el-form ref="form" :model="form" label-width="100px" size="small">
      <el-form-item label="是否合并公司">
        <el-select
          v-model="form.correlation"
          placeholder="请选择是否合并公司"
          filterable
        >
          <el-option label="是" value="是"> </el-option>
          <el-option label="否" value="否"> </el-option>
        </el-select>
        <span style="color:#17a2b8"><i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin:0 3px"></i>注意：选择否则拆分公司</span>
      </el-form-item>
      <el-form-item label="主公司" v-if="form.correlation == '是'">
        <el-select
          v-model="form.mainComId"
          placeholder="请选择主公司"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">
          取消
        </el-button>
        <el-button
          type="primary"
          @click="combineSure()"
          size="small"
          :loading="btnLoading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { companyMerge } from "@/api/company";
export default {
  name: "companyCombine",
  data() {
    return {
      dialogVisible: false,
      form: {
        mainComId: null,
        comIds: [],
        correlation: "是",
      },
      btnLoading: false,
    };
  },

  methods: {
    init(list) {
      this.reset();
      this.options = list;
      this.dialogVisible = true;
    },
    reset() {
      this.form = {
        mainComId: null,
        comIds: [],
        correlation: "是",
      };
    },
    combineSure() {
      if (this.form.correlation == "是" && !this.form.mainComId) {
        this.$qzfMessage("请选择主公司", 1);
        return;
      }
      let ids = [];
      this.options.map((v) => {
        if (v.id != this.form.mainComId) {
          ids.push(v.id);
        }
      });
      this.form.comIds = ids;
      this.btnLoading = true;
      companyMerge(this.form).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("操作成功");
          this.dialogVisible = false;
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
