<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="修改税种启用状态"
    v-model="dialogTableVisible"
    width="550px"
  >
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="税种" prop="projectName">
        <el-select
          v-model="form.projectName"
          placeholder="请选择税种"
          size="small"
          filterable
          style="width: 250px;margin: 0;"
        >
          <el-option
            v-for="item in collection"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="征收品目">
        <el-input size="small" v-model="form.itemName" placeholder="请输入" style="width: 250px;"></el-input>
      </el-form-item>
      <el-form-item label="启用状态" prop="qyzt">
        <el-radio-group v-model="form.qyzt">
          <el-radio label="0">启用</el-radio>
          <el-radio label="1">不启用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveSetting()"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { saveTaxCategoryQyzt } from "@/api/taxCalculation";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      form: {
        comIds: [],
        projectName: "",
        itemName: "",
        qyzt:""
      },
      collection: [
        {
          value: "增值税",
          label: "增值税",
        },
        {
          value: "城镇土地使用税",
          label: "城镇土地使用税",
        },
        {
          value: "房产税",
          label: "房产税",
        },
        {
          value: "印花税",
          label: "印花税",
        },
        {
          value: "资源税",
          label: "资源税",
        },
        {
          value: "工会经费",
          label: "工会经费",
        },
        {
          value: "消费税",
          label: "消费税",
        },
        {
          value: "残疾人就业保障金",
          label: "残疾人就业保障金",
        },
        {
          value: "企业所得税",
          label: "企业所得税",
        },
        {
          value: "个人所得税",
          label: "个人所得税",
        },
        {
          value: "教育费附加",
          label: "教育费附加",
        },
        {
          value: "地方教育附加",
          label: "地方教育附加",
        },
        {
          value: "水利建设专项收入",
          label: "水利建设专项收入",
        },
        {
          value: "财务报表",
          label: "财务报表",
        },
        {
          value: "资产负债表",
          label: "资产负债表",
        },
        {
          value: "利润表",
          label: "利润表",
        },
        {
          value: "现金流量表",
          label: "现金流量表",
        },
        {
          value: "城市生活垃圾处置费",
          label: "城市生活垃圾处置费",
        },
        {
          value: "城市维护建设税",
          label: "城市维护建设税",
        },
        {
          value: "文化事业建设费",
          label: "文化事业建设费",
        },
        {
          value:"环境保护税",
          label:"环境保护税"
        },
      ],

      rules: {
        projectName: [
          { required: true, message: "请选择税种", trigger: "change" },
        ],
        qyzt: [
          { required: true, message: "请选择启用状态", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    init(item) {
      let ids = [];
      item.map((v) => {
        ids.push(v.id);
      });
      this.form = {
        comIds: ids,
        projectName: "",
        itemName: "",
        qyzt:""
      };
      this.dialogTableVisible = true;
    },
    saveSetting() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          saveTaxCategoryQyzt(this.form).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("请稍后查看",1);
              this.dialogTableVisible = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
