<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="新增税种"
    v-model="dialogFormVisible"
    width="500px"
  >
    <el-scrollbar :maxHight="contentStyleObj">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-width="110px"
        size="small"
        class="form_style"
      >
        <el-form-item label="税种" prop="collectionProject">
          <el-select
            v-model="temp.collectionProject"
            placeholder="请选择税种"
            filterable
            @change="getItems"
          >
            <el-option
              v-for="item in collection"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="征收品目"
          prop="collectionItems"
          v-if="itemOptions && itemOptions.length > 1"
        >
          <el-select
            v-model="temp.collectionItems"
            placeholder="请选择征收品目"
            filterable
          >
            <el-option
              v-for="item in itemOptions"
              :key="item.id"
              :label="item.item_mame"
              :value="item.item_mame"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="征收子目" prop="collectionSub">
          <el-input v-model="temp.collectionSub"> </el-input>
        </el-form-item>
        <el-form-item label="申报周期" prop="types">
          <el-select
            v-model="temp.types"
            multiple
            collapse-tags
            collapse-tags-tooltip
            @change="changeType(temp)"
          >
            <el-option
              v-for="item in dateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用时间" prop="validityPeriodStart">
          <el-input v-model.trim="temp.validityPeriodStart"> </el-input>
          <el-tooltip content="时间格式为:2001-01-01" placement="top">
            <i
              class="iconfont icon-wenhao"
              style="color:red;cursor: pointer;font-size:13px;margin-left: 5px;"
            ></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="结束时间" prop="validityPeriodEnd">
          <el-input v-model.trim="temp.validityPeriodEnd"> </el-input>
        </el-form-item>
        <el-form-item label="税率(小数)" prop="rate">
          <el-input v-model="temp.rate"> </el-input>
        </el-form-item>
        <el-form-item
          label="申报表"
          prop="dqde"
          v-if="temp.collectionProject == '个人所得税'"
        >
          <el-select v-model="temp.dqde" clearable>
            <el-option label="定额核定" value="定额核定"></el-option>
            <el-option label="定率征收" value="定率征收"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="核定经营额"
          prop="hdjye"
          v-if="temp.dqde == '定额核定'"
        >
          <el-input v-model="temp.hdjye"> </el-input>
        </el-form-item>
        <el-form-item
          label="核定比例(小数)"
          prop="yhHdbl"
          v-if="temp.collectionProject == '印花税'"
        >
          <el-input v-model.number="temp.yhHdbl" type="number"> </el-input>
        </el-form-item>
        <el-form-item label="启用状态" prop="qyzt">
          <el-radio-group v-model="temp.qyzt">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">不启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="createData()"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogCallVisible"
    title="提示"
  >
    <el-table :data="callList" border>
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="公司名称" min-width="180">
      </el-table-column>
      <el-table-column prop="remark" label="失败原因" min-width="180">
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { taxCategoryListItem, saveTaxCategorys } from "@/api/taxCalculation";
export default {
  name: "batchAddCategory",
  data() {
    return {
      list: [],
      dialogFormVisible: false,
      contentStyleObj: {},
      temp: {
        collectionProject: "",
        collectionItems: "",
        collectionSub: "",
        types: [],
        validityPeriodStart: "",
        validityPeriodEnd: "",
        rate: "",
        dqde: "",
        yhHdbl: 0,
        qyzt: 0,
      },
      collection: [
        {
          value: "增值税",
          label: "增值税",
        },
        {
          value: "城镇土地使用税",
          label: "城镇土地使用税",
        },
        {
          value: "房产税",
          label: "房产税",
        },
        {
          value: "印花税",
          label: "印花税",
        },
        {
          value: "资源税",
          label: "资源税",
        },
        {
          value: "工会经费",
          label: "工会经费",
        },
        {
          value: "消费税",
          label: "消费税",
        },
        {
          value: "残疾人就业保障金",
          label: "残疾人就业保障金",
        },
        {
          value: "企业所得税",
          label: "企业所得税",
        },
        {
          value: "个人所得税",
          label: "个人所得税",
        },
        {
          value: "教育费附加",
          label: "教育费附加",
        },
        {
          value: "地方教育附加",
          label: "地方教育附加",
        },
        {
          value: "水利建设专项收入",
          label: "水利建设专项收入",
        },
        {
          value: "资产负债表",
          label: "资产负债表",
        },
        {
          value: "利润表",
          label: "利润表",
        },
        {
          value: "现金流量表",
          label: "现金流量表",
        },
        {
          value: "城市生活垃圾处置费",
          label: "城市生活垃圾处置费",
        },
        {
          value: "城市维护建设税",
          label: "城市维护建设税",
        },
        {
          value: "文化事业建设费",
          label: "文化事业建设费",
        },
        {
          value:"环境保护税",
          label:"环境保护税"
        },
      ],
      dateOptions: [
        {
          value: "月",
          label: "月报",
          disabled: false,
        },
        {
          value: "季",
          label: "季报",
          disabled: false,
        },
        {
          value: "年",
          label: "年报",
          disabled: false,
        },
        {
          value: "次",
          label: "次报",
          disabled: false,
        },
        {
          value: "不申报",
          label: "不申报",
          disabled: false,
        },
        {
          value: "01",
          label: "一月",
          disabled: false,
        },
        {
          value: "02",
          label: "二月",
          disabled: false,
        },
        {
          value: "03",
          label: "三月",
          disabled: false,
        },
        {
          value: "04",
          label: "四月",
          disabled: false,
        },
        {
          value: "05",
          label: "五月",
          disabled: false,
        },
        {
          value: "06",
          label: "六月",
          disabled: false,
        },
        {
          value: "07",
          label: "七月",
          disabled: false,
        },
        {
          value: "08",
          label: "八月",
          disabled: false,
        },
        {
          value: "09",
          label: "九月",
          disabled: false,
        },
        {
          value: "10",
          label: "十月",
          disabled: false,
        },
        {
          value: "11",
          label: "十一月",
          disabled: false,
        },
        {
          value: "12",
          label: "十二月",
          disabled: false,
        },
      ],
      itemOptions: [],
      rules: {
        collectionProject: [
          { required: true, message: "请选择税种", trigger: "change" },
        ],
        types: [
          { required: true, message: "请选择申报周期", trigger: "change" },
        ],
        validityPeriodStart: [
          { required: true, message: "请输入启用时间", trigger: "blur" },
        ],
        validityPeriodEnd: [
          { required: true, message: "请输入结束时间", trigger: "blur" },
        ],
      },
      comIds: [],
      btnLoading: false,
      callList: [],
      dialogCallVisible: false,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(230);
  },

  methods: {
    //弹窗
    init(e) {
      this.resetTemp();
      this.comIds = e;
      this.dialogFormVisible = true;
    },
    createData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let saveList = [];
          this.comIds.map((v) => {
            saveList.push({
              comId: v,
              collectionProject: this.temp.collectionProject,
              collectionItems: this.temp.collectionItems,
              collectionSub: this.temp.collectionSub,
              type: this.temp.types.join(","),
              validityPeriodStart: this.temp.validityPeriodStart,
              validityPeriodEnd: this.temp.validityPeriodEnd,
              rate: this.temp.rate,
              dqde: this.temp.dqde,
              yhHdbl: this.temp.yhHdbl,
              qyzt: this.temp.qyzt,
            });
          });
          saveTaxCategorys({ list: saveList }).then((res) => {
            this.btnLoading = false;
            if (res.data.data && res.data.data.length != 0) {
              this.callList = res.data.data;
              this.dialogCallVisible = true;
            } else {
              this.$qzfMessage("保存成功");
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },
    getItems() {
      this.temp.collectionItems = "";
      taxCategoryListItem({ project: this.temp.collectionProject }).then(
        (res) => {
          this.itemOptions = res.data.data.list ? res.data.data.list : [];
        }
      );
    },
    //修改一个时
    changeType(temp) {
      if (temp.types.includes("季") || temp.types.includes("月")) {
        this.dateOptions.map((v) => {
          if (
            v.value != "季" &&
            v.value != "月" &&
            v.value != "次" &&
            v.value != "不申报"
          ) {
            v.disabled = true;
          }
        });
      } else if (
        temp.types.includes("次") ||
        temp.types.includes("不申报") ||
        temp.types.length == 0
      ) {
        this.dateOptions.map((v) => {
          v.disabled = false;
        });
      } else {
        this.dateOptions.map((v) => {
          if (v.value == "季" || v.value == "月") {
            v.disabled = true;
          } else {
            v.disabled = false;
          }
        });
      }
    },
    resetTemp() {
      this.temp = {
        collectionProject: "",
        collectionItems: "",
        collectionSub: "",
        types: [],
        validityPeriodStart: "",
        validityPeriodEnd: "",
        rate: "",
        dqde: "",
        yhHdbl: 0,
        qyzt: 0,
      };
      this.dateOptions.map(v=>{
        v.disabled = false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.form_style {
  .el-select {
    width: 85%;
    margin: 0;
  }
  .el-input {
    width: 85%;
  }
}
</style>
