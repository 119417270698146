<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
    <div style="width:76%;float:left;margin-bottom:10px" v-if="tabName == 'seventh' || tabName == 'sixth' || tabName == 'ninth' || tabName == 'fifth'">
      <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
      <el-input size="small" placeholder="请输入公司名称/编号" v-model.trim="listQuery.name" style="width:200px" @keyup.enter="getList" clearable></el-input>
      <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
        <el-icon><Search /></el-icon><span  > 搜索</span>
      </el-button>
      <search @success="getList" @cancel="cancel">
        <el-form style="width:580px" label-width="110px" :inline="true" class="styleForm" size="small">
          <el-form-item label="纳税人类型：">   
            <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
          </el-form-item>
          <el-form-item label="选择人员：">
            <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px"></selectuser>
          </el-form-item>
          <el-form-item label="税局："> 
            <selectcity v-model:citys="listQuery.districtCode" style="width:140px"></selectcity>
          </el-form-item>
          <el-form-item label="当前账期："> 
            <qzf-search-period v-model:period="listQuery.period" :clearable="true" style="width:140px"></qzf-search-period>
          </el-form-item>
          <el-form-item label="截止账期："> 
            <qzf-search-period v-model:period="listQuery.curPeriod" :clearable="true" style="width:140px"></qzf-search-period>
          </el-form-item>
          <el-form-item label="最后结账月："> 
            <qzf-search-period v-model:period="listQuery.lastJzPeriod" :clearable="true" style="width:140px"></qzf-search-period>
          </el-form-item>
          <el-form-item label="公司类型："> 
            <el-select v-model="listQuery.companyType" placeholder="请选择公司类型"  filterable clearable style="width:140px">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="会计准则："> 
            <el-select v-model="listQuery.comKj" placeholder="请选择会计准则" style="width:140px;" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登录状态：">   
            <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
          </el-form-item>
          <el-form-item label="手机号：">   
            <input-common v-model:modelValue="listQuery.mobileTel" style="width:140px"></input-common>
          </el-form-item>
          <el-form-item label="标签设置状态：">   
            <el-select v-model="listQuery.lableEaStatus" placeholder="请选择标签设置状态" style="width:140px;margin-left: 0px">
              <el-option label="全部" value=""></el-option>
              <el-option label="已设置" value="1"></el-option>
              <el-option label="未设置" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签：">   
            <input-common v-model:modelValue="listQuery.labelLogoEa" style="width:140px"></input-common>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <el-tab-pane label="税款申报" name="seventh">
      <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'seventh'" :listQuery="listQuery"></contentTable>
    </el-tab-pane>
    <el-tab-pane label="零申报" name="sixth">
      <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'sixth'" :listQuery="listQuery"></contentTable>
    </el-tab-pane>
    <el-tab-pane label="停报客户" name="ninth">
      <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'ninth'" :listQuery="listQuery"></contentTable>
    </el-tab-pane>
    <el-tab-pane label="回收站" name="fifth">
      <contentTable ref="clientList" :tabName="tabName" :listQuery="listQuery" v-if="tabName == 'fifth'"></contentTable>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import selectcity from "@/components/Screening/selectcity";
import { defineComponent, ref, onBeforeUnmount } from 'vue'
import contentTable from "./components/contentTable.vue";
import { assignCustomers } from "@/api/printSet"
import { ACCOUNT_SYSTEM_OPTION,COMPANY_TYPE_OPTION } from '@/utils/commonData.js'
export default {
  name: 'bookkeeping',
  components:{
    selecttaxtype,
    selectcity,
    contentTable,
    selectAccreditStatus
  },

  mounted () {
  //  if(this.activeName == 'seventh'){
  //   this.$refs.clientList.init()
  //  }
  },
  data() {
    return {
      period: '202202',
      listQuery: {
        page: 1,
        limit: 20,
        accountingType:2,
        status:'2',
        companyType:"",
        type:"",
        userId:null,
        districtCode:"",
        period:"",
        keepStatus:'',
        comKj:'',
        curPeriod:""
      },
      userTotal: 2,
      activeName: "seventh",
      tabName:'seventh',
      options: ACCOUNT_SYSTEM_OPTION,
      typeOptions: COMPANY_TYPE_OPTION,
    }
  },
  created () {
    this.initBus()
  },
  methods: {
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      let originAccountingType = this.listQuery.accountingType
      let originStatus = this.listQuery.status
      this.listQuery = {
        page: 1,
        limit: originLimit,
        accountingType:originAccountingType,
        status:originStatus,
        companyType:"",
        type:"",
        userId:null,
        districtCode:"",
        period:"",
        keepStatus:'',
        comKj:''
      }
      this.$nextTick(()=>{
        this.getList()
      })
    },
    initBus(){
      this.$bus.off("bookkeepingUpdate")
      this.$bus.on("bookkeepingUpdate", (val) => {
        this.getList()
      });
      this.$bus.off("stopReportingUpdate")
      this.$bus.on("stopReportingUpdate", (val) => {
        this.getList()
      });
    },
    getList(){
      this.$refs.clientList.getList()
    },
    redirection() {
      this.$store.dispatch('tagsView/delCachedViewByName', "redirection")
      this.$router.push({
        path: "../../company/redirection.vue",
        name: "redirection"
      });
    },
    handleClick(tab, event) {
      this.listQuery.page = 1;
      this.listQuery.limit = 20;
      if (tab.props.label == "零申报") {
        this.listQuery.status = "2"
        this.tabName = tab.props.name
        this.listQuery.accountingType = 1
      } else if (tab.props.label == "税款申报") {
        this.listQuery.status = "2"
        this.tabName = tab.props.name
        this.listQuery.accountingType = 2
      } else if (tab.props.label == "不可申报") {
        this.tabName = tab.props.name
        this.listQuery.accountingType = 3
      }else if (tab.props.label == "停报客户") {
        this.reset()
        this.listQuery.status = "3"
        this.tabName = tab.props.name
        this.listQuery.accountingType = null
      }else if (tab.props.label == "回收站") {
        this.reset()
        this.listQuery.status = "9"
        this.tabName = tab.props.name
        this.listQuery.accountingType = null
      }
    },
    customerExport(){
      let param = {
        query:this.listQuery
      }
      assignCustomers(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    reset(){
      this.listQuery.companyType = "";
      this.listQuery.type = "";
      this.listQuery.userId = null;
      this.listQuery.districtCode = "";
      this.listQuery.period = ""
    }
  },
  activated () {
    
  },
  setup() {
    onBeforeUnmount(()=>{
      //console.log("2销毁")
    })
  },
}
</script>
<style lang="scss" scoped>
.home{
  padding: 10px;
  .header{
    margin-bottom: 10px;
    .right{
      float: right;
    }
    .input-search{
      width: 180px;
      margin-right: 5px;
    }
  }
  .img-ewm{
    width: 50px;
  }
}
.pagination{
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
 
.styleForm{
  .el-select{
    margin: 0px;
  }
}
</style>