import request from '@/utils/request'
//迁移列表
export function oldOrgList(data) {
  return request({
    url: '/api/v2/move/oldOrgList',
    method: 'post',
    data
  })
}

//迁移
export function moveOrg(data) {
  return request({
    url: '/api/v2/move/moveOrg',
    method: 'post',
    data
  })
}
//采集
export function companyMove(data) {
  return request({
    url: '/api/v2/move/companyMove',
    method: 'post',
    data
  })
}

//历史数据迁移
export function companyOldMove(data) {
  return request({
    url: '/api/v2/move/companyOldMove',
    method: 'post',
    data
  })
}

//顶呱呱获取迁移密钥
export function getComSecret(data) {
  return request({
    url: '/api/v2/move/getComSecret',
    method: 'post',
    data
  })
}
