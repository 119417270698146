import request from '@/utils/request'

// 发起协助
export function sendHelp(data) {
  return request({
    url: 'api/v2/help/sendHelp',
    method: 'post',
    data
  })
}

// 结束协助
export function helpOver(data) {
  return request({
    url: 'api/v2/help/helpOver',
    method: 'post',
    data
  })
}

// 开始协助
export function helpStart(data) {
  return request({
    url: 'api/v2/help/helpStart',
    method: 'post',
    data
  })
}

// 授权页面列表
export function getRemoteHelperList(data) {
  return request({
    url: 'api/v2/help/getRemoteHelperList',
    method: 'post',
    data
  })
}
// 公司分配列表
export function getHelpOrg(data) {
  return request({
    url: 'api/v2/help/getHelpOrg',
    method: 'post',
    data
  })
}
// 客服人员列表
export function getHelpUsers(data) {
  return request({
    url: 'api/v2/help/getHelpUsers',
    method: 'post',
    data
  })
}
// 客服账号穿梭框
export function getHelpList(data) {
  return request({
    url: 'api/v2/help/getHelpList',
    method: 'post',
    data
  })
}
// 客服账号穿梭框保存
export function getHelpSave(data) {
  return request({
    url: 'api/v2/help/getHelpSave',
    method: 'post',
    data
  })
}
//操作日志查询
export function searchEs(data) {
  return request({
    url: 'api/v2/searchEs',
    method: 'post',
    data
  })
}