import request from '@/utils/request'

// 学校机构列表
export function findEduOrg(data) {
  return request({
    url: 'api/v2/eduOrgCom/findEduOrg',
    method: 'post',
    data
  })
}

// 分配教学包
export function authTechPkg(data) {
  return request({
    url: 'api/v2/eduOrgCom/authTechPkg',
    method: 'post',
    data
  })
}